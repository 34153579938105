import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo.svg";
import { getLanguage } from "./Translate";

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
const PageNavbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
    
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  getLocale() {
    return (this.props.path || '').split('/')[2] || ''
  }

  render() {
    const language = this.props.language ?? 'de';
    const localeSwitch = (language === 'en' ?  'de' : 'en');
    const flags = {
      de: 'de',
      en: 'gb'
    };
    const labels = {
      intention: {
        de: 'Intention',
        en: 'Intention'
      },
      releases: {
        de: 'Veröffentlichungen',
        en: 'Releases'
      },
      about: {
        de: 'Über mich',
        en: 'About me'
      },
      languageSwitch: {
        de: 'Switch to english',
        en: 'Auf Deutsch lesen',
      }
    }
    
    const url = "";
    return ( 
      <Navbar bg="white" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href={"/" + language}>Matthias Göbel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link active={this.props.path === `/${language}/intention`} href={`/${language}/intention`}>{labels.intention[language]}</Nav.Link>
              <Nav.Link active={this.props.path === `/${language}/releases`} href={`/${language}/releases`}>{labels.releases[language]}</Nav.Link> 
            </Nav>
            <Nav>
              <Nav.Link active={this.props.path === `/${language}/about`} href={`/${language}/about`}>{labels.about[language]}</Nav.Link>
              <Nav.Link active={this.props.path === `/${language}/impressum`} href={`/${language}/impressum`}>Impressum</Nav.Link>

              <Nav.Link style={{marginLeft: '2em'}} active={language == 'de'} title={labels.languageSwitch['en']} href={`/${localeSwitch}/${this.getLocale()}`}><span className="flag-icon flag-icon-de" ></span></Nav.Link>
              <Nav.Link style={{}} active={language == 'en'} title={labels.languageSwitch['de']} href={`/${localeSwitch}/${this.getLocale()}`}><span className="flag-icon flag-icon-gb" ></span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
};

export default PageNavbar;
